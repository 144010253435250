
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import {
    ActionOn,
    DataGridAction,
    toDisplayColumns,
    useDataGridModel,
    useDeleteModalAction,
} from "@/core-ui/data-grid/compositions";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import ModelDetailsPanel from "../components/jobs/JobDetailsPanel.vue";
import Page from "@/core-ui/Page.vue";
import { createModelProps, meta, ModalType as Item } from "@/models/jobs.model";
import { useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import { computed, defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import clusterJobService from "@/cluster-ui/services/job.service";
import DeleteModalAction from "@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import { Job } from "@/types/job";
import projectsService from "@/services/projects-service";
import { storageService } from "@/core-ui/data-grid/services";
import { JOBS_TABLE_FILTER } from "@/cluster-ui/helpers/const";
import authStore from "@/stores/authStore";

export default defineComponent({
    components: {
        Page,
        ButtonSelect,
        DataGrid,
        ModelDetailsPanel,
        DeleteModalAction,
    },
    setup() {
        const HttpStatusCompound = 207;
        const props = useClusterUUIDKey({ key: "nodes" });
        const initialSearchQuery = ref<string | undefined>(undefined);
        const filterUserJobs = computed<boolean>(
            () =>
                authStore.isOnlyResearcherOrResearcherManager ||
                authStore.isOnlyResearcherOrResearcherManagerOrMlEngineer,
        );
        if (filterUserJobs.value) {
            initialSearchQuery.value = localStorage.getItem(JOBS_TABLE_FILTER) || "";
        }
        const selectedMode = computed<string>({
            get: () => {
                const v = storageService.getStr("jobs_view", "History");
                if (v == "Current") {
                    router.push("/jobs");
                }
                return v;
            },
            set: (v) => storageService.setStr("jobs_view", v),
        });
        const model = useDataGridModel(
            createModelProps(props, "page", {
                get mode() {
                    return selectedMode.value;
                },
            }),
        );
        const router = useRouter();
        const state = reactive({
            projectsList: null,
        });
        const isAdmin = authStore.userInfo.roles.includes("admin");

        async function fetchProjectsList() {
            state.projectsList = await projectsService.getDesiredQueues();
        }

        fetchProjectsList();

        const actionDisabledMsgs = {
            connectivityProblem: (activity: string) => `Connectivity to cluster is required in order to ${activity}`,
            versionProblem: (activity: string) =>
                `To ${activity}, your cluster needs to have the latest version installed.`,
            noProjectProblem: () =>
                isAdmin
                    ? "To submit a new job, First create at least one project"
                    : "To create a job, you need to be assigned to a project. Contact your administrator to be assigned. ",
        };

        function handleJobAction(d: any, actionName: string) {
            //
            //   handle compound error. This is temporary placeholder, until multiple-job request
            //   is supported. For now, if we get compound and the status of the first (and only)
            //   job indicate action failed, raise exception so an error message will be displayed.
            //
            if (d.status == HttpStatusCompound && d.data.data?.length) {
                if (d.data.data.some((job: any) => !job.ok)) {
                    throw `${actionName} failed`;
                }
            } else {
                setTimeout(() => model.refresh(), 4000);
            }
        }
        const mapToClusterJobId = ({ project, jobName }: Job) => ({ project, name: jobName } as any);
        const deleteModal = useDeleteModalAction<Item>({
            modelMeta: meta,
            delete: (jobs: Item[]) =>
                clusterJobService.delete(jobs.map(mapToClusterJobId)).then((d) => {
                    handleJobAction(d, "Deletion");
                    return d;
                }) as any,
        });

        const isResearcherAndNotProduction = computed(() => authStore.isResearcher);
        (window as any).ss = ref(false);

        const hiddenKeys = ["status"];

        const TrainingJob = "Train"; // the db and cluster jobs are differance ("Train", "Training")
        return {
            state,
            model,
            deleteModal,
            selectedMode,
            initialSearchQuery,
            get hiddenKeys() {
                return hiddenKeys;
            },
            get displayColumns() {
                return toDisplayColumns(null, model.columns, hiddenKeys);
            },
            actions: [
                {
                    on: ActionOn.Item,
                    key: "logs",
                    icon: "raicon-log",
                    label: `Logs`,
                    aid: "log-job",
                    permitted: true,
                    disabled: () => !clusterApi.connected && actionDisabledMsgs.connectivityProblem("show job's logs"),
                    filter: (j: Job) => j.existsInCluster && isResearcherAndNotProduction.value,
                    func: (job: any) => router.push(`/jobs/${job.project}/${job.jobName}/${job.podGroupId}/log`) as any,
                },
                {
                    on: ActionOn.Item,
                    icon: "raicon-copy",
                    permitted: true,
                    key: "clone",
                    label: "Clone Job",
                    aid: "clone-job",

                    disabled: () => !clusterApi.connected && actionDisabledMsgs.connectivityProblem("clone job"),
                    filter: (job: Job) =>
                        job.existsInCluster && isResearcherAndNotProduction.value && job.parentWorkloadName,
                    func: (job: any) =>
                        router.push(
                            `/jobs/submit?JobName=${job.parentWorkloadName}&JobProject=${job.project}&JobType=${job.jobType}`,
                        ) as any,
                },
            ] as DataGridAction[],
        };
    },
});
