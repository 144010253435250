import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModalAction = _resolveComponent("DeleteModalAction")!
  const _component_ButtonSelect = _resolveComponent("ButtonSelect")!
  const _component_ModelDetailsPanel = _resolveComponent("ModelDetailsPanel")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_DeleteModalAction, { state: _ctx.deleteModal }, null, 8, ["state"]),
      _createVNode(_component_DataGrid, {
        model: _ctx.model,
        actions: _ctx.actions,
        "sync-url": true,
        "hidden-keys": _ctx.hiddenKeys,
        "initial-search-query": _ctx.initialSearchQuery
      }, {
        aftersearch: _withCtx(() => [
          _createVNode(_component_ButtonSelect, {
            options: [{ value: 'Current' }, { value: 'History' }],
            modelValue: _ctx.selectedMode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMode) = $event))
          }, null, 8, ["modelValue"])
        ]),
        item: _withCtx((props) => [
          _createVNode(_component_ModelDetailsPanel, {
            job: props.item,
            columns: _ctx.displayColumns,
            "is-history": _ctx.selectedMode === 'History',
            onClose: props.clearSelection
          }, null, 8, ["job", "columns", "is-history", "onClose"])
        ]),
        _: 1
      }, 8, ["model", "actions", "hidden-keys", "initial-search-query"])
    ]),
    _: 1
  }))
}